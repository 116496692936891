export const transColor={
   "パステルブルー":"#00BFFF",
   "エメラルドグリーン":"#00FA9A",
   "グリーン":"#00FF00",
   "パールグリーン":"#00FF7F",
   "ライトグリーン":"#EBFF00",
   "イエロー":"#FFFF00",
   "オレンジ":"#FFA500",
   "レッド":"#FF0000",
   "ホワイト":"#FFF",
   "サクラピンク":"#FF87CF",
   "ピンク":"#FF2883",
   "パッションピンク":"#FF2055",
   "バイオレット":"#C750FF",
   "パープル":"#7A3CFF",
   "ブルー":"#0000FF"
}
export type Color=keyof typeof transColor;
export const colors=Object.keys(transColor);

export interface Member {
   name:string,
   term:number,
   hiragana:boolean,
   graduated:boolean,
   color:Color[],
}

export const perfectMessages=[
   "こんなに正解しちゃっていいの？",
   "勝つしかねぇので！",
   "完璧なの。",
   "天才",
   "全問正解しか勝たん",
   "月と星が踊るPerfect",
   "ｱﾊﾟｰ",
   "全問正解したっていいじゃん♪"
]

export const membersData:Member[]=[
   {
      name:"井口眞緒",
      term:1,
      hiragana:false,
      graduated:true,
      color:["レッド","オレンジ"]
   },
   {
      name:"潮紗理菜",
      term:1,
      hiragana:false,
      graduated:true,
      color:["パープル","イエロー"]
   },
   {
      name:"柿崎芽実",
      term:1,
      hiragana:false,
      graduated:true,
      color:["パールグリーン","パールグリーン"]
   },
   {
      name:"影山優佳",
      term:1,
      hiragana:false,
      graduated:true,
      color:["レッド","レッド"]
   },
   {
      name:"加藤史帆",
      term:1,
      hiragana:false,
      graduated:false,
      color:["ブルー","ブルー"]
   },
   {
      name:"齊藤京子",
      term:1,
      hiragana:false,
      graduated:true,
      color:["ホワイト","ホワイト"]
   },
   {
      name:"佐々木久美",
      term:1,
      hiragana:false,
      graduated:false,
      color:["パステルブルー","パープル"]
   },
   {
      name:"佐々木美玲",
      term:1,
      hiragana:false,
      graduated:false,
      color:["イエロー","イエロー"]
   },
   {
      name:"高瀬愛奈",
      term:1,
      hiragana:true,
      graduated:false,
      color:["ピンク","ホワイト"]
   },
   {
      name:"高本彩花",
      term:1,
      hiragana:false,
      graduated:true,
      color:["パステルブルー","サクラピンク"]
   },
   {
      name:"東村芽依",
      term:1,
      hiragana:false,
      graduated:false,
      color:["サクラピンク","サクラピンク"]
   },
   {
      name:"金村美玖",
      term:2,
      hiragana:false,
      graduated:false,
      color:["イエロー","パステルブルー"]
   },
   {
      name:"河田陽菜",
      term:2,
      hiragana:false,
      graduated:false,
      color:["サクラピンク","イエロー"]
   },
   {
      name:"小坂菜緒",
      term:2,
      hiragana:false,
      graduated:false,
      color:["バイオレット","ホワイト"]
   },
   {
      name:"富田鈴花",
      term:2,
      hiragana:false,
      graduated:false,
      color:["パープル","パープル"]
   },
   {
      name:"丹生明里",
      term:2,
      hiragana:false,
      graduated:false,
      color:["オレンジ","オレンジ"]
   },
   {
      name:"濱岸ひより",
      term:2,
      hiragana:true,
      graduated:false,
      color:["オレンジ","ホワイト"]
   },
   {
      name:"松田好花",
      term:2,
      hiragana:false,
      graduated:false,
      color:["サクラピンク","パールグリーン"]
   },
   {
      name:"宮田愛萌",
      term:2,
      hiragana:false,
      graduated:true,
      color:["レッド","サクラピンク"]
   },
   {
      name:"渡邉美穂",
      term:2,
      hiragana:false,
      graduated:true,
      color:["ホワイト","パステルブルー"]
   },
   {
      name:"上村ひなの",
      term:3,
      hiragana:false,
      graduated:false,
      color:["レッド","エメラルドグリーン"]
   },
   {
      name:"髙橋未来虹",
      term:3,
      hiragana:false,
      graduated:false,
      color:["グリーン","パープル"]
   },
   {
      name:"森本茉莉",
      term:3,
      hiragana:true,
      graduated:false,
      color:["オレンジ","ブルー"]
   },
   {
      name:"山口陽世",
      term:3,
      hiragana:true,
      graduated:false,
      color:["パールグリーン","イエロー"]
   },
   {
      name:"石塚瑶季",
      term:4,
      hiragana:true,
      graduated:false,
      color:["サクラピンク","オレンジ"]
   },
   {
      name:"岸帆夏",
      term:4,
      hiragana:false,
      graduated:true,
      color:["イエロー","ホワイト"]
   },
   {
      name:"小西夏菜実",
      term:4,
      hiragana:false,
      graduated:false,
      color:["パープル","ブルー"]
   },
   {
      name:"清水理央",
      term:4,
      hiragana:true,
      graduated:false,
      color:["パールグリーン","オレンジ"]
   },
   {
      name:"正源司陽子",
      term:4,
      hiragana:false,
      graduated:false,
      color:["オレンジ","レッド"]
   },
   {
      name:"竹内希来里",
      term:4,
      hiragana:true,
      graduated:false,
      color:["イエロー","レッド"]
   },
   {
      name:"平尾帆夏",
      term:4,
      hiragana:true,
      graduated:false,
      color:["パステルブルー","オレンジ"]
   },
   {
      name:"平岡海月",
      term:4,
      hiragana:true,
      graduated:false,
      color:["ブルー","イエロー"]
   },
   {
      name:"藤嶌果歩",
      term:4,
      hiragana:false,
      graduated:false,
      color:["サクラピンク","ブルー"]
   },
   {
      name:"宮地すみれ",
      term:4,
      hiragana:true,
      graduated:false,
      color:["バイオレット","レッド"]
   },
   {
      name:"山下葉留花",
      term:4,
      hiragana:true,
      graduated:false,
      color:["ホワイト","エメラルドグリーン"]
   },
   {
      name:"渡辺莉奈",
      term:4,
      hiragana:true,
      graduated:false,
      color:["パープル","ピンク"]
   }
]

